// plugins/vuetify.js
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import defaults from './vuetify/defaults'
// import { icons } from './vuetify/icons'
import { themes } from './vuetify/theme'
import { VBtn } from 'vuetify/components/VBtn'

import '@/styles/core/template/libs/vuetify/index.scss'
import 'vuetify/styles'

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify({
    ssr: true,
    components,
    directives,
    aliases: {
      IconBtn: VBtn,
    },
    defaults,
    // icons,
    theme: {
      defaultTheme: 'light',
      themes,
    },
  })

  nuxtApp.vueApp.use(vuetify)
})