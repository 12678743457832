import { elevation } from '@googlemaps/google-maps-services-js/dist/elevation';
import { VBtnGroup, VTextarea, VTextField } from 'vuetify/components';

export default {
  IconBtn: {
    icon: true,
    color: 'default',
    variant: 'text',
  },
  VAlert: {
    rounded: "lg",
    elevation: 1,

    VBtn: {
      color: undefined,
    },
  },
  VAvatar: {
    // ℹ️ Remove after next release
    variant: 'flat',
  },
  VBadge: {
    // set v-badge default color to primary
    color: 'primary',
  },
  VBtnGroup: {
    rounded: 'lg',
    elevation: 0,
    density: 'default',
    ripple: false,
    variant: 'tonal'
  },
  VBtn: {
    // set v-btn default color to primary
    color: 'primary',
    rounded: "lg",
    elevation: 0,
    density: 'default',
    ripple: false,
    variant: 'tonal'
  },
  VChip: {
    elevation: 0,
  },
  VMenu: {
    offset: '2px',
  },
  VPagination: {
    density: 'compact',
    showFirstLastPage: false,
    variant: 'plain',
    totalVisible: 3,
    rounded: 'lg',
  },
  VCard: {
    rounded: 'lg',
    elevation: 1,
    ripple: false
  },
  VSkeletonLoader: {
    elevation: 1,
    rounded: 'lg'
  },
  VTabs: {
    // set v-tabs default color to primary
    color: 'primary',
    VSlideGroup: {
      showArrows: true,
    },
  },
  VTooltip: {
    // set v-tooltip default location to top
    location: 'top',
  },
  VCheckboxBtn: {
    color: 'primary',
  },
  VCheckbox: {
    // set v-checkbox default color to primary
    color: 'primary',
    density: 'comfortable',
    hideDetails: 'auto',
  },
  VRadioGroup: {
    color: 'primary',
    density: 'comfortable',
    hideDetails: 'auto',
  },
  VRadio: {
    density: 'comfortable',
    hideDetails: 'auto',
  },
  VSelect: {
    variant: 'outlined',
    color: 'primary',
    hideDetails: 'auto',
    density: 'comfortable',
  },
  VRangeSlider: {
    // set v-range-slider default color to primary
    color: 'primary',
    thumbLabel: true,
    hideDetails: 'auto',
    trackSize: 6,
    thumbSize: 22,
    elevation: 4,
  },
  VRating: {
    // set v-rating default color to primary
    activeColor: 'warning',
    color: 'disabled',
  },
  VProgressCircular: {
    // set v-progress-circular default color to primary
    color: 'primary',
  },
  VProgressLinear: {
    color: 'primary',
  },
  VSlider: {
    // set v-slider default color to primary
    color: 'primary',
    trackSize: 6,
    hideDetails: 'auto',
    thumbSize: 22,
    elevation: 4,
  },
  VSnackbar: {
    VBtn: {
      size: 'small',
    },
  },
  VTextField: {
    variant: 'outlined',
    density: 'comfortable',
    color: 'primary',
    hideDetails: 'auto',
    rounded: 'lg',
  },
  VAutocomplete: {
    variant: 'outlined',
    color: 'primary',
    density: 'comfortable',
    hideDetails: 'auto',
  },
  VCombobox: {
    variant: 'outlined',
    color: 'primary',
    hideDetails: 'auto',
    density: 'comfortable',
  },
  VFileInput: {
    variant: 'outlined',
    color: 'primary',
    hideDetails: 'auto',
    density: 'comfortable',
  },
  VTextarea: {
    variant: 'outlined',
    color: 'primary',
    hideDetails: 'auto',
    density: 'comfortable',
    rounded: 'lg'
  },
  VSwitch: {
    // set v-switch default color to primary
    inset: true,
    color: 'primary',
    hideDetails: 'auto',
  },
  VNavigationDrawer: {
    touchless: true,
  },
}
