import { default as indexclOP3FC17pMeta } from "/opt/render/project/src/pages/admin/appointment/index.vue?macro=true";
import { default as billinga4M1Rru9TwMeta } from "/opt/render/project/src/pages/admin/billing.vue?macro=true";
import { default as calendarJlMu3FqOqkMeta } from "/opt/render/project/src/pages/admin/calendar.vue?macro=true";
import { default as indexwpPCkQ1tsrMeta } from "/opt/render/project/src/pages/admin/company/index.vue?macro=true";
import { default as employeesuqn5YKtsQdMeta } from "/opt/render/project/src/pages/admin/company/location/[id]/employees.vue?macro=true";
import { default as indexapTmWrcPtYMeta } from "/opt/render/project/src/pages/admin/company/location/[id]/index.vue?macro=true";
import { default as servicesfYsNhepQ2wMeta } from "/opt/render/project/src/pages/admin/company/location/[id]/services.vue?macro=true";
import { default as workinghoursGij4oAHhbMMeta } from "/opt/render/project/src/pages/admin/company/location/[id]/workinghours.vue?macro=true";
import { default as _91id_93Kjdy7VWSr3Meta } from "/opt/render/project/src/pages/admin/company/location/[id].vue?macro=true";
import { default as companyuY5UYoW2stMeta } from "/opt/render/project/src/pages/admin/company.vue?macro=true";
import { default as index1CMK6HxdVHMeta } from "/opt/render/project/src/pages/admin/customers/index.vue?macro=true";
import { default as dashboardqQacmY3YUAMeta } from "/opt/render/project/src/pages/admin/dashboard.vue?macro=true";
import { default as index7bX5qL9w3WMeta } from "/opt/render/project/src/pages/admin/index.vue?macro=true";
import { default as transactionsiG8DjcBITJMeta } from "/opt/render/project/src/pages/admin/payment/transactions.vue?macro=true";
import { default as profile92V2GB5BgYMeta } from "/opt/render/project/src/pages/admin/profile.vue?macro=true";
import { default as settingsCC2OY5iwCFMeta } from "/opt/render/project/src/pages/admin/settings.vue?macro=true";
import { default as indexeav7fDvd0dMeta } from "/opt/render/project/src/pages/admin/user/index.vue?macro=true";
import { default as confirm_45signupC8Ne7vbytXMeta } from "/opt/render/project/src/pages/auth/confirm-signup.vue?macro=true";
import { default as confirmEt1wqsE9zNMeta } from "/opt/render/project/src/pages/auth/confirm.vue?macro=true";
import { default as email_45confirmationF1QT4XTB0qMeta } from "/opt/render/project/src/pages/auth/email-confirmation.vue?macro=true";
import { default as login_45userOOIiOTPlHpMeta } from "/opt/render/project/src/pages/auth/login-user.vue?macro=true";
import { default as loginRWqn9SRgU9Meta } from "/opt/render/project/src/pages/auth/login.vue?macro=true";
import { default as recoveryPpoqATNLcmMeta } from "/opt/render/project/src/pages/auth/recovery.vue?macro=true";
import { default as register_45userBM1CZZPxu3Meta } from "/opt/render/project/src/pages/auth/register-user.vue?macro=true";
import { default as registertIhrm2P3u1Meta } from "/opt/render/project/src/pages/auth/register.vue?macro=true";
import { default as _91id_935iYQHGciEeMeta } from "/opt/render/project/src/pages/booking/[id].vue?macro=true";
import { default as contact15m9h4kbk7Meta } from "/opt/render/project/src/pages/contact.vue?macro=true";
import { default as faqKgR8rdA5zoMeta } from "/opt/render/project/src/pages/faq.vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as agbUrixuTvUNwMeta } from "/opt/render/project/src/pages/legal/agb.vue?macro=true";
import { default as data_45protectionxu5NstDifPMeta } from "/opt/render/project/src/pages/legal/data-protection.vue?macro=true";
import { default as impressum1nk9PsDFjjMeta } from "/opt/render/project/src/pages/legal/impressum.vue?macro=true";
import { default as listings_45not_45foundYRQcqPJ2vKMeta } from "/opt/render/project/src/pages/listings-not-found.vue?macro=true";
import { default as bookingcLwy1TT3YlMeta } from "/opt/render/project/src/pages/listings/[id]/booking.vue?macro=true";
import { default as indexseIpDXDG4DMeta } from "/opt/render/project/src/pages/listings/[id]/index.vue?macro=true";
import { default as indexVjz9mqJosnMeta } from "/opt/render/project/src/pages/listings/index.vue?macro=true";
import { default as listingsuMim5hvNRdMeta } from "/opt/render/project/src/pages/listings.vue?macro=true";
import { default as profileD6N3qH7A15Meta } from "/opt/render/project/src/pages/profile.vue?macro=true";
import { default as _91id_93e9nDMWWfK3Meta } from "/opt/render/project/src/pages/review/[id].vue?macro=true";
import { default as thankyou0wiQX24G4ZMeta } from "/opt/render/project/src/pages/thankyou.vue?macro=true";
import { default as usecasespBMPn3Dw68Meta } from "/opt/render/project/src/pages/usecases.vue?macro=true";
import { default as index8vw2SgPPqGMeta } from "~/pages/listings/index.vue?macro=true";
import { default as component_45stubzFO8P7pzWFMeta } from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubzFO8P7pzWF } from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin-appointment",
    path: "/admin/appointment",
    meta: indexclOP3FC17pMeta || {},
    component: () => import("/opt/render/project/src/pages/admin/appointment/index.vue")
  },
  {
    name: "admin-billing",
    path: "/admin/billing",
    meta: billinga4M1Rru9TwMeta || {},
    component: () => import("/opt/render/project/src/pages/admin/billing.vue")
  },
  {
    name: "admin-calendar",
    path: "/admin/calendar",
    meta: calendarJlMu3FqOqkMeta || {},
    component: () => import("/opt/render/project/src/pages/admin/calendar.vue")
  },
  {
    name: companyuY5UYoW2stMeta?.name,
    path: "/admin/company",
    component: () => import("/opt/render/project/src/pages/admin/company.vue"),
    children: [
  {
    name: "admin-company",
    path: "",
    meta: indexwpPCkQ1tsrMeta || {},
    component: () => import("/opt/render/project/src/pages/admin/company/index.vue")
  },
  {
    name: _91id_93Kjdy7VWSr3Meta?.name,
    path: "location/:id()",
    meta: _91id_93Kjdy7VWSr3Meta || {},
    component: () => import("/opt/render/project/src/pages/admin/company/location/[id].vue"),
    children: [
  {
    name: "admin-company-location-id-employees",
    path: "employees",
    component: () => import("/opt/render/project/src/pages/admin/company/location/[id]/employees.vue")
  },
  {
    name: "admin-company-location-id",
    path: "",
    component: () => import("/opt/render/project/src/pages/admin/company/location/[id]/index.vue")
  },
  {
    name: "admin-company-location-id-services",
    path: "services",
    component: () => import("/opt/render/project/src/pages/admin/company/location/[id]/services.vue")
  },
  {
    name: "admin-company-location-id-workinghours",
    path: "workinghours",
    component: () => import("/opt/render/project/src/pages/admin/company/location/[id]/workinghours.vue")
  }
]
  }
]
  },
  {
    name: "admin-customers",
    path: "/admin/customers",
    meta: index1CMK6HxdVHMeta || {},
    component: () => import("/opt/render/project/src/pages/admin/customers/index.vue")
  },
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: dashboardqQacmY3YUAMeta || {},
    component: () => import("/opt/render/project/src/pages/admin/dashboard.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: index7bX5qL9w3WMeta || {},
    component: () => import("/opt/render/project/src/pages/admin/index.vue")
  },
  {
    name: "admin-payment-transactions",
    path: "/admin/payment/transactions",
    meta: transactionsiG8DjcBITJMeta || {},
    component: () => import("/opt/render/project/src/pages/admin/payment/transactions.vue")
  },
  {
    name: "admin-profile",
    path: "/admin/profile",
    meta: profile92V2GB5BgYMeta || {},
    component: () => import("/opt/render/project/src/pages/admin/profile.vue")
  },
  {
    name: "admin-settings",
    path: "/admin/settings",
    meta: settingsCC2OY5iwCFMeta || {},
    component: () => import("/opt/render/project/src/pages/admin/settings.vue")
  },
  {
    name: "admin-user",
    path: "/admin/user",
    meta: indexeav7fDvd0dMeta || {},
    component: () => import("/opt/render/project/src/pages/admin/user/index.vue")
  },
  {
    name: "auth-confirm-signup",
    path: "/auth/confirm-signup",
    meta: confirm_45signupC8Ne7vbytXMeta || {},
    component: () => import("/opt/render/project/src/pages/auth/confirm-signup.vue")
  },
  {
    name: "auth-confirm",
    path: "/auth/confirm",
    meta: confirmEt1wqsE9zNMeta || {},
    component: () => import("/opt/render/project/src/pages/auth/confirm.vue")
  },
  {
    name: "auth-email-confirmation",
    path: "/auth/email-confirmation",
    meta: email_45confirmationF1QT4XTB0qMeta || {},
    component: () => import("/opt/render/project/src/pages/auth/email-confirmation.vue")
  },
  {
    name: "auth-login-user",
    path: "/auth/login-user",
    meta: login_45userOOIiOTPlHpMeta || {},
    component: () => import("/opt/render/project/src/pages/auth/login-user.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginRWqn9SRgU9Meta || {},
    component: () => import("/opt/render/project/src/pages/auth/login.vue")
  },
  {
    name: "auth-recovery",
    path: "/auth/recovery",
    meta: recoveryPpoqATNLcmMeta || {},
    component: () => import("/opt/render/project/src/pages/auth/recovery.vue")
  },
  {
    name: "auth-register-user",
    path: "/auth/register-user",
    meta: register_45userBM1CZZPxu3Meta || {},
    component: () => import("/opt/render/project/src/pages/auth/register-user.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registertIhrm2P3u1Meta || {},
    component: () => import("/opt/render/project/src/pages/auth/register.vue")
  },
  {
    name: "booking-id",
    path: "/booking/:id()",
    meta: _91id_935iYQHGciEeMeta || {},
    component: () => import("/opt/render/project/src/pages/booking/[id].vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contact15m9h4kbk7Meta || {},
    component: () => import("/opt/render/project/src/pages/contact.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqKgR8rdA5zoMeta || {},
    component: () => import("/opt/render/project/src/pages/faq.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexYy8SidDWaAMeta || {},
    component: () => import("/opt/render/project/src/pages/index.vue")
  },
  {
    name: "legal-agb",
    path: "/legal/agb",
    meta: agbUrixuTvUNwMeta || {},
    component: () => import("/opt/render/project/src/pages/legal/agb.vue")
  },
  {
    name: "legal-data-protection",
    path: "/legal/data-protection",
    meta: data_45protectionxu5NstDifPMeta || {},
    component: () => import("/opt/render/project/src/pages/legal/data-protection.vue")
  },
  {
    name: "legal-impressum",
    path: "/legal/impressum",
    meta: impressum1nk9PsDFjjMeta || {},
    component: () => import("/opt/render/project/src/pages/legal/impressum.vue")
  },
  {
    name: "listings-not-found",
    path: "/listings-not-found",
    component: () => import("/opt/render/project/src/pages/listings-not-found.vue")
  },
  {
    name: listingsuMim5hvNRdMeta?.name,
    path: "/listings",
    component: () => import("/opt/render/project/src/pages/listings.vue"),
    children: [
  {
    name: "listings-id-booking",
    path: ":id()/booking",
    component: () => import("/opt/render/project/src/pages/listings/[id]/booking.vue")
  },
  {
    name: "listings-id",
    path: ":id()",
    meta: indexseIpDXDG4DMeta || {},
    component: () => import("/opt/render/project/src/pages/listings/[id]/index.vue")
  },
  {
    name: "listings",
    path: "",
    component: () => import("/opt/render/project/src/pages/listings/index.vue")
  }
]
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileD6N3qH7A15Meta || {},
    component: () => import("/opt/render/project/src/pages/profile.vue")
  },
  {
    name: "review-id",
    path: "/review/:id()",
    meta: _91id_93e9nDMWWfK3Meta || {},
    component: () => import("/opt/render/project/src/pages/review/[id].vue")
  },
  {
    name: "thankyou",
    path: "/thankyou",
    meta: thankyou0wiQX24G4ZMeta || {},
    component: () => import("/opt/render/project/src/pages/thankyou.vue")
  },
  {
    name: "usecases",
    path: "/usecases",
    meta: usecasespBMPn3Dw68Meta || {},
    component: () => import("/opt/render/project/src/pages/usecases.vue")
  },
  {
    name: "katalog",
    path: "/katalog",
    component: () => import("~/pages/listings/index.vue")
  },
  {
    name: "katalog-ort",
    path: "/katalog/:city",
    component: () => import("~/pages/listings/index.vue")
  },
  {
    name: "katalog-ort-service",
    path: "/katalog/:city/:category",
    component: () => import("~/pages/listings/index.vue")
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/pages-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  },
  {
    name: component_45stubzFO8P7pzWFMeta?.name,
    path: "/locations-sitemap.xml",
    component: component_45stubzFO8P7pzWF
  }
]